<template>
  <kurcc-data-table-page-template :key="key"
                                  :data-table-title="getDataTableTitle"
                                  :default-item="defaultItem"
                                  :get-all-action-additional-params="prepareAdditionalParams"
                                  :headers="headers"
                                  :show-delete-action="false"
                                  :show-toolbar-button="false"
                                  :show-update-action="false"
                                  actions-suffix="Inspection"
                                  initial-sort-by-column="inspection_due_date">
  </kurcc-data-table-page-template>
</template>

<script>
import Inspection from '@/modules/app/models/inspection'

export default {
  name: 'KurccInspectionsPage',
  components: {
    KurccDataTablePageTemplate: () => import('@/modules/app/templates/KurccDataTablePageTemplate')
  },
  data () {
    return {
      headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.inspections.tableColumns.inspectionSite'),
          align: 'start',
          value: 'site.name',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.inspections.tableColumns.Group'),
          value: 'group_name',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.inspections.tableColumns.dueDate'),
          value: 'inspection_due_date'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.inspections.tableColumns.inspector'),
          value: 'inspector_name',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.inspections.tableColumns.address'),
          value: 'address',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.inspections.tableColumns.deadline'),
          value: 'deadline_days'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.inspections.tableColumns.score'),
          value: 'score'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.inspections.tableColumns.grade'),
          value: 'grade'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.inspections.tableColumns.type'),
          value: 'type'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pages.dashboard.inspections.tableColumns.actions'),
          value: 'actions',
          sortable: false
        }
      ],
      defaultItem: new Inspection()
    }
  },
  computed: {
    prepareAdditionalParams () {
      return [`site_id=${this.$auth.user().site.id}`]
    },
    getDataTableTitle () {
      return this.$vuetify.lang.t('$vuetify.pages.dashboard.inspections.inspectionList')
    }
  }
}
</script>
